import { Order } from '@wix/ambassador-pricing-plans-v2-order/types';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { CheckoutData, IntegrationData, StatusData } from '@wix/pricing-plans-router-utils';
import { navigateToHeadlessIfNeeded, NavigationType } from '@wix/wix-to-headless-redirect-client';
import { ControllerParams, IUser } from '@wix/yoshi-flow-editor';
import { EXPERIMENTS } from '../../../constants';
import { Analytics } from '../../../services/analytics';
import { CommonProps, MessageCode, SubPage } from '../../../types/common';
import { dateToYMD } from '../../../utils/date';
import { Emitter } from '../../../utils/Emitter';
import { isEqual } from '../../../utils/isEqual';
import { isFree, isStartDateCustomizable } from '../../../utils/plan';
import { Navigation } from './Navigation';

interface WhenLoginEvents {
  checkout: { data: CheckoutData; user: IUser };
  list: { data: never; user: IUser };
  status: { data: never; user: IUser };
  restricted: { data: never; user: IUser };
}

interface InitializationEvents {
  list: IntegrationData;
  checkout: CheckoutData;
  status: StatusData;
  restricted: { pageId: string; integrationData: IntegrationData };
}

interface RoutingEvents {
  list: { integrationData: IntegrationData; message?: MessageCode };
  checkout: { plan: PublicPlan; order?: Order; integrationData: IntegrationData };
  status: { statusData: StatusData; plan: PublicPlan };
  restricted: { pageId: string; integrationData: IntegrationData };
}

type AllListeners<T, K extends keyof T = keyof T> = {
  [P in K]?: (payload: T[P]) => Promise<void>;
};

interface GoToStatusParams {
  plan: PublicPlan;
  order: Order;
  integrationData: IntegrationData;
  config?: Partial<StatusViewConfig>;
  guestCheckoutEnabled: boolean;
}

interface StatusViewConfig {
  ok: boolean;
  error?: string;
  ownerDemo?: boolean;
}

export class Router {
  protected lastPage?: SubPage;
  protected initialization: Emitter<InitializationEvents> = new Emitter();
  protected routing: Emitter<RoutingEvents> = new Emitter();

  constructor(
    protected setProps: (props: Partial<CommonProps>) => void,
    protected nav: Navigation,
    protected analytics: Analytics,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected flowAPI: ControllerParams['flowAPI'],
  ) {}

  async initialize() {
    const subPage = await this.nav.getCurrentSubPage();
    if (!isEqual(this.lastPage, subPage)) {
      this.setProps({ message: undefined });
      this.setPage(subPage);
      const emitter = this.initialization;
      switch (subPage.name) {
        case 'checkout':
          return emitter.emit('checkout', subPage.checkoutData);
        case 'status':
          return emitter.emit('status', subPage.statusData);
        case 'restricted':
          return emitter.emit('restricted', subPage);
        default:
        case 'list':
          return emitter.emit('list', subPage.integrationData);
      }
    }
  }

  whenNavigate({ list, checkout, status, restricted }: AllListeners<RoutingEvents>) {
    list && this.routing.on('list', list);
    checkout && this.routing.on('checkout', checkout);
    status && this.routing.on('status', status);
    restricted && this.routing.on('restricted', restricted);
  }

  whenInit({ list, checkout, status, restricted }: AllListeners<InitializationEvents>) {
    list && this.initialization.on('list', list);
    checkout && this.initialization.on('checkout', checkout);
    status && this.initialization.on('status', status);
    restricted && this.initialization.on('restricted', restricted);
  }

  whenLogin({ checkout }: AllListeners<WhenLoginEvents>) {
    this.wixCodeApi.user.onLogin(async (user: IUser) => {
      const subPage = await this.nav.getCurrentSubPage();
      switch (subPage.name) {
        case 'checkout':
          await checkout?.({ user, data: subPage.checkoutData });
          break;
      }
    });
  }

  async gotoList(integrationData: IntegrationData, message?: MessageCode) {
    await this.routing.emit('list', { integrationData, message });
    this.navigateTo({ name: 'list', integrationData });
  }

  async gotoCheckout(plan: PublicPlan, integrationData: IntegrationData, order?: Order) {
    await this.routing.emit('checkout', { plan, integrationData, order });
    const {
      currentUser: { loggedIn, id },
    } = this.wixCodeApi.user;
    this.navigateTo({
      name: 'checkout',
      checkoutData: {
        memberId: order && loggedIn ? id : undefined,
        integrationData,
        orderId: order?.id,
        planId: plan.id!,
      },
    });
  }

  async gotoStatus({ plan, order, integrationData, config = {}, guestCheckoutEnabled }: GoToStatusParams) {
    const { ok: successful, error, ownerDemo } = { ok: true, ownerDemo: false, ...config };
    const { startDate } = order;
    const statusData: StatusData = {
      purchaseData: {
        checkoutData: {
          integrationData,
          planId: plan.id!,
          orderId: order.id!,
          guestCheckoutEnabled,
        },
        orderId: order.id!,
        snapshotId: order.wixPayOrderId!,
        freeTrialDays: order.freeTrialDays ?? undefined,
      },
      successful,
      error,
      ownerDemo,
      startDate: isStartDateCustomizable(plan) && startDate ? dateToYMD(startDate) : undefined,
      planName: plan.name ?? undefined,
    };
    if (successful && !ownerDemo) {
      this.analytics.purchase(plan, order, isFree(plan) ? 'yes' : 'unknown');
    }
    const runInternalNavigation = async () => {
      await this.routing.emit('status', { statusData, plan });
      this.navigateTo({ name: 'status', statusData });
    };
    if (this.flowAPI.experiments.enabled(EXPERIMENTS.HEADLESS_SUPPORT)) {
      navigateToHeadlessIfNeeded({
        navParams: {
          logicalName: NavigationType.PLANS_THANK_YOU_PAGE,
          params: {
            planOrderId: order.id,
          },
        },
        location: this.wixCodeApi.location,
        fallbackNavigation: runInternalNavigation,
      });
    } else {
      await runInternalNavigation();
    }
  }

  async gotoHomePage() {
    const { pages } = await this.wixCodeApi.site.getSiteStructure();
    const homePage = pages.find((page) => page.isHomePage);
    if (homePage?.url) {
      this.wixCodeApi.location.to?.(homePage.url);
    }
  }

  protected navigateTo(subPage: SubPage) {
    this.nav.setCurrentPath(subPage);
    if (!this.nav.isMultiPageApp) {
      this.setPage(subPage);
    }
  }

  protected setPage(subPage: SubPage) {
    this.lastPage = subPage;
    this.setProps({ subPage });
  }
}
