import { Order } from '@wix/ambassador-pricing-plans-v2-order/types';
import type { IntegrationData, StatusData, ThankYouPublicData } from '@wix/pricing-plans-router-utils';
import type { ThankYouPageSettings } from '@wix/pricing-plans-utils/client-data';
import type { CreateControllerFn, ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { plansFixtureTranslated } from '../../fixtures';
import { createPlansApi } from '../../services';
import { Analytics } from '../../services/analytics';
import { WarmupData } from '../../services/WarmupData';
import { Navigation } from '../PackagePicker/controller/Navigation';
import { Router } from '../PackagePicker/controller/Router';
import { StatusController } from '../PackagePicker/controller/StatusController';

function demoStatusData(t: ControllerFlowAPI['translations']['t']): StatusData {
  const demoPlans = plansFixtureTranslated(t);
  return {
    ownerDemo: false,
    successful: true,
    planName: demoPlans[0].name!,
    purchaseData: {
      checkoutData: { integrationData: {}, planId: '00000000-0000-0000-0000-000000000000' },
      orderId: '00000000-0000-0000-0000-000000000000',
      snapshotId: '00000000-0000-0000-0000-000000000000',
    },
  };
}

export interface ThankYouWidgetProps {
  settings?: ThankYouPageSettings;
  successful?: boolean;
  translatedError?: string;
  order?: Order;
  startDate?: string;
  ownerDemo?: boolean;
  integrationData: IntegrationData;
  navigateBackToCheckout(): void;
  navigateFromStatusPage(): void;
  biThankYouPageCtaButtonClick(): void;
  biThankYouPageOnLoad(): void;
  biUpgradeReferralClick(referralInfo: string): void;
  metaSiteId?: string;
}

export const createController: CreateControllerFn = async ({ flowAPI }) => {
  const { wixCodeApi, appParams, compId, setProps: untypedSetProps } = flowAPI.controllerConfig;

  const setProps: (props: Partial<ThankYouWidgetProps>) => void = untypedSetProps;

  const plansApi = createPlansApi(flowAPI.httpClient);
  const analytics = new Analytics(wixCodeApi.window);
  const warmupData = new WarmupData(compId, wixCodeApi, flowAPI);
  const nav = new Navigation(wixCodeApi, flowAPI, appParams.appDefinitionId, true);
  const router = new Router(setProps, nav, analytics, wixCodeApi, flowAPI);
  const status = new StatusController(setProps, wixCodeApi, flowAPI, router, plansApi, warmupData);

  return {
    async pageReady() {
      const routerData = wixCodeApi.window.getRouterPublicData<ThankYouPublicData>();

      const plan = routerData?.plan ?? undefined;
      const statusData = routerData?.statusData ?? demoStatusData(flowAPI.translations.t);

      await status.update(statusData, plan);
    },
    async updateConfig(_$w, _newConfig) {},
  };
};
