export const EXPERIMENTS = {
  USE_BLOCKS_SINGLE_PLAN_WIDGET: 'specs.membership.UseBlocksSinglePlanWidget',
  USE_BLOCKS_PLAN_LIST_WIDGET: 'specs.membership.UseBlocksPlanListWidget',
  USE_BLOCKS_PACKAGE_PICKER_PAGE: 'specs.membership.UseBlocksPackagePickerPage',
  ALERT_MODALS: 'specs.membership.AlertModals',
  FORCE_MEMBERS_AREA_INSTALL: 'specs.membership.ForceMemberAreaInstall',
  FORCE_MEMBERS_AREA_NO_INSTALL: 'specs.membership.ForceMemberAreaNoInstall',
  ACCEPT_PAYMENTS_ERROR: 'specs.membership.AcceptPaymentsError',
  BLOCKS_LAYOUT_CUSTOMIZATION: 'specs.membership.BlocksLayoutCustomization',
  HEADLESS_SUPPORT: 'specs.membership.HeadlessSupport',
  PLAN_NAME_LINES: 'specs.membership.PlanNameLines',
  INSTALL_SPLIT_PAGES: 'specs.membership.InstallSplitPages',
  APPLY_INSTALLATION_FIX: 'specs.membership.ApplyInstallationFix',
  BLOCKS_ADD_WIDGET_PROGRESS: 'specs.membership.BlocksAddWidgetProgress',
  BLOCKS_SET_CONTROLLER_STATE_ON_LOAD: 'specs.membership.BlocksSetControllerStateOnLoad',
  ROUTER: 'specs.membership.EnableRouter',
  ROUTER_MIGRATION: 'specs.membership.RouterMigration',
  STORYBOOK_FIX: 'specs.membership.StorybookFix',
};
